@import "../layout/Cora.less";
.SelectionModal.ant-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    @apply bg-neutral-200;
    border-radius: 8px 8px 0 0;
    padding: 16px 20px;
  }
  .ant-modal-close {
    top: 36px;
    right: 36px;
  }
  .ant-modal-body {
    padding: 0;
    background-color: white;
  }
  .ant-modal-footer {
    @apply bg-neutral-200;
    border-radius: 0 0 8px 8px;
  }
}
