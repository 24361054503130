.CRCFacets {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #414141;
  }
  .ant-tabs,
  .ant-tabs-content-holder,
  .ant-tabs-content,
  .ant-tabs-tabpane-active {
    height: 100%;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 16px;
  }
}
