@import "../../../assets/css/diagramCommon.less";

.ConstraintsBar {
  //background-color: white;
  .ant-tabs-nav {
    margin-bottom: 0;
    font-weight: normal;

    .ant-tabs-tab {
      display: flex;
      align-items: center;
      padding: 2px 4px;
      margin: 4px;
      font-size: 13px;
      color: @TEXT;
      border: none;
      background-color: transparent;

      .ant-tabs-tab-remove {
        margin-bottom: 6px;
        margin-left: 2px;
        visibility: hidden;
      }
      &:hover {
        .ant-tabs-tab-btn {
          background-color: hsl(var(--twc-subtle));
        }
        .ant-tabs-tab-remove {
          visibility: visible;
        }
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: hsl(var(--twc-primary_heavy));
          text-shadow: unset;
          border-bottom-color: hsl(var(--twc-primary_heavy));
        }
      }
    }

    .ant-tabs-nav-add {
      display: none;
    }
  }
}
