.EvidenceFilter .ant-input-affix-wrapper {
  background-color: transparent;

  > .ant-input {
    background-color: transparent;
    font-size: inherit;
    border: none;
    border-color: transparent;
  }

  &.ant-input-affix-wrapper-focused {
    border-radius: inherit;
    border: none;
    border-color: transparent;
    background: inherit;
  }
}
