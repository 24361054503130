.HistorySubtable .ant-table-thead tr th {
  color: rgb(25, 25, 25);
  background-color: rgb(251, 251, 251);
}

.HistorySubtable
  .ant-table-thead
  th.ant-table-cell.ant-table-column-has-sorters:hover {
  color: rgb(25, 25, 25);
  background-color: rgb(225, 225, 225);
}
