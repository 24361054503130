.FacetsForRange {
  .react-datepicker-wrapper {
    display: flex;
    flex: 1 1 100%;
    width: auto;
  }
  .react-datepicker__input-container {
    display: flex;
    flex: 1 1 100%;
    width: auto;
  }

  input {
    flex: 1 1 100%;
    width: 0px;
  }
}
