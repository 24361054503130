.Document mark {
  padding: 0;
  @apply bg-core_yellow-500;
}

.Document a {
  text-decoration: underline;
}

.Document p {
  margin-bottom: 2em;
}

.Document ul {
  margin-bottom: 2em;
}

.Document ul li {
  margin-left: 1em;
  list-style-type: disc;
}
