@import "../../../assets/css/diagramCommon.less";

#GraphTabs .ant-tabs-content,
.ant-tabs-tabpane {
  height: 100%;
}

.ConstraintFlow {
  .react-flow__handle {
    opacity: 0;
  }
  .react-flow {
    .react-flow__pane {
      cursor: default;
      .grabbing& {
        cursor: grabbing;
      }

      .panning& {
        cursor: all-scroll;
      }

      .selecting& {
        cursor: default;
      }

      .connecting& {
        cursor: crosshair;
      }

      .query& {
        cursor: help;
      }
    }
  }

  .react-flow__node {
    height: unset;
    cursor: pointer;
    font-size: @TEXT_SIZE;
    color: @TEXT;
    background-color: transparent;
    text-align: center;
    border-style: solid;
    border-color: transparent;
    opacity: 0.9;
    display: flex;
    backdrop-filter: blur(15px);
    outline: @OUTLINE;

    .node-label {
      text-align: left;
    }

    &.active {
      border-width: @ACTIVE_BORDER_WIDTH;
      background-color: hsl(var(--twc-subtle));

      .node-icon,
      .node-label,
      .node-chevron,
      .node-expansion,
      .node-clear {
        color: hsl(var(--twc-primary_heavy));
      }
    }

    &:not(.react-flow__node-RELATION) {
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    &.selected {
      outline: @SELECTED_OUTLINE;
      &.active {
        outline: @ACTIVE_OUTLINE;
      }
    }

    &.Unavailable {
      opacity: 0.3;
    }

    &.merging {
      outline: @SELECTED_OUTLINE;
      cursor: @MERGING-CURSOR;
      background-color: @MERGING_COLOR;
      .node-header {
        cursor: @MERGING-CURSOR;
      }
    }

    &.dragging {
      cursor: default;
      .node-header {
        cursor: default;
      }
      .react-flow__resize-control.handle.bottom.right {
        visibility: hidden !important;
      }
    }
    .node-header {
      cursor: grab;
    }

    .Batch {
      display: flex;
      background-color: hsl(var(--twc-primary_heavy));
      padding: 0 4px;
      border-radius: 4px;
      align-items: center;
      color: white;
      height: 16px;
    }

    .sourceHandle,
    .node-chevron,
    .node-expansion,
    .node-clear {
      visibility: hidden;
    }

    &:hover {
      .sourceHandle,
      .node-chevron,
      .node-expansion,
      .node-clear,
      .react-flow__resize-control.handle.bottom.right {
        visibility: visible;
      }
    }

    .react-flow__resize-control {
      visibility: hidden;
      &.handle.bottom.right {
        width: 10px;
        height: 10px;
        background-color: white;
        border-color: @NORMAL_BORDER;
        border-width: 1px;
        z-index: 1;
      }
    }

    div.targetHandle {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      transform: none;
      border: none;
      opacity: 0;
    }

    div.sourceHandle {
      position: absolute;
      top: 50%;
      left: 100%;
      height: @CONNECTOR-SIZE;
      width: @CONNECTOR-SIZE;
      transform: translate(calc(-7px), (-50%));
      background: @BACKGROUND;
      z-index: 1000;
      border-radius: calc(@CONNECTOR-SIZE / 2);
      font-size: 10px;
      border: 1px solid @NORMAL_BORDER;
    }

    .react-flow__nodesselection-rect {
      display: none;
    }
  }

  .react-flow__attribution {
    display: none;
  }

  .active-edge > path, .active-edge > polyline {
    stroke: black !important;
  }

  .react-flow__edge {
    path {
      stroke-width: 1px;
      stroke: hsl(var(--twc-border_primary));
    }

    &.active path {
      stroke-width: 1px;
      stroke: hsl(var(--twc-primary_heavy));
    }

    &.Unavailable {
      opacity: 0.3;
    }
  }

  .react-flow__arrowhead polyline {
  }

  .react-flow__edge-text {
    font-size: 10px;
    font-style: italic;
  }

  .react-flow__edge-QUALIFIER path {
    stroke-dasharray: 5;
  }

  .selection-area {
    fill: @SELECTION_AREA_COLOR;
    stroke: @SELECTION_AREA_BORDER;
    stroke-dasharray: 6;
    opacity: 0.5;
  }
}
