.FacetTree.ant-tree {
  background-color: unset;
}
.FacetTree .ant-tree-treenode {
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  padding: 0 0 0;
  font-size: 12px;
  outline: none;
  overflow: hidden;
}

.FacetTree .ant-tree-treenode .ant-tree-node-content-wrapper,
.FacetTree .ant-checkbox-wrapper,
.FacetTree .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
  display: flex;
  overflow: hidden;
  flex: 1;
}
.FacetTree
  .ant-tree-treenode
  .ant-tree-node-content-wrapper
  .ant-checkbox-wrapper
  > span:nth-child(2) {
  overflow: hidden;
}

.FacetTree .ant-tree-treenode:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.FacetTree .ant-tree-treenode .auto_hide {
  visibility: hidden;
}
.FacetTree .ant-tree-treenode:hover .auto_hide {
  visibility: unset;
}

.FacetTree .ant-tree-indent-unit {
  display: inline-block;
  width: 12px;
}

//.FacetTree.Hierarchical .ant-checkbox-wrapper {
//  padding-left: 0;
//}

.FacetTree .ant-tree-switcher {
  width: 10px;
}
.FacetTree.Flat .ant-tree-switcher {
  width: 0;
}

.FacetTree.Flat .ant-tree-switcher {
  width: 0;
}

.FacetTree .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 4px;
}

.FacetTree.Flat .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.FacetTree {
  position: relative;
}
.FacetTree .ant-tree-list {
  width: 100%;
}
