#QuestionInput::placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
#QuestionInput {
  font-style: normal;
  color: rgba(0, 0, 0);
}

.QuestionInputDropdown .ant-select-item-option-active {
  background-color: rgba(187, 187, 187, 0.5);
}
