.ClusterPicker {
  .ant-tabs-tab {
    padding: 2px;
    .ant-tabs-tab-btn {
      padding: 6px 24px;
      color: black;
      border-radius: 8px;
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      background: #ffffff;
      color: black;
      text-shadow: none;

      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}
