@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./assets/fonts/Inter/inter.css");

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

em {
  font-weight: bold;
}
