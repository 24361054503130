.Version {
  > .ant-popover-content {
    > .ant-popover-arrow {
      > .ant-popover-arrow-content {
        &::before {
          background-color: #32153d;
        }
      }
    }
  }
  .ant-popover-inner {
    background-color: unset;
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
