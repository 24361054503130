.SupportingRefuting {
  .ant-tabs-nav {
    margin: 0 0 10px 0;
  }
  .ant-tabs-tab-btn {
    @apply text-sm;
  }
  .ant-tabs-tab {
    padding: 0.25rem 0;
  }
}
